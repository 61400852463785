export const state = {
  authId: null,
}

export const getters = {
  authId: (state) => {
    return _.get(state, "authId")
  },
}

export const actions = {
  setAuthId({ commit }, authId) {
    commit("SET_AUTH_ID", authId)
  },
}

export const mutations = {
  SET_AUTH_ID(state, authId) {
    state.authId = authId
  },
}
